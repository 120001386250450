<template>
  <footer class="relative bg-opacity-0">
    <div class="lg:-10 mx-auto max-w-screen-xl bg-opacity-0 p-5 py-8 md:p-8">
      <hr class="mx-auto mb-16 h-px border-0 bg-brick-300" />
      <p class="mb-12 max-w-72 text-3xl">Wir machen Gesundheit einfach.</p>
      <div class="my-8 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
        <div v-for="(section, idx) in data?.linkSection" :key="idx">
          <h2 v-if="section.title" class="mb-4 font-medium text-gray-900">
            {{ section.title }}
          </h2>
          <ul class="text-gray-800">
            <li v-for="link in section.links" :key="link.id" class="mb-4">
              <NuxtLink
                v-if="link.href"
                :to="link.href"
                :target="isOutgoing(link.href) ? '_blank' : '_self'"
                class="hover:underline"
                >{{ link.label }}
              </NuxtLink>
              <span v-else>{{ link.label }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="departments.length > 0" class="my-8 grid md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
        <div class="text-gray-900">
          <div class="lg:mb-8">
            <div class="mb-4 text-base font-medium text-gray-900">Fachrichtungen</div>
          </div>
        </div>
        <ul class="col-span-3 grid text-gray-800 md:grid-cols-2 lg:grid-cols-3">
          <template v-for="department in departments" :key="department.id">
            <li v-if="department.attributes.sea && department.attributes.sea.length > 0" class="mb-4 grow">
              <NuxtLink
                :to="`/${department.attributes.sea[0].slug}`"
                :title="department.attributes.sea[0].title"
                class="hover:underline"
                >{{ department.attributes.title }}
              </NuxtLink>
            </li>
          </template>
        </ul>
      </div>

      <div class="xk:mt-44 mt-14 md:mt-32">
        <ul class="mt-5 flex justify-center space-x-6 md:justify-start">
          <li v-for="i in data?.socialMediaButtons?.buttons" :key="i.href">
            <AppLink :to="i.href">
              <ImageStrapiImage
                v-if="i.image?.data"
                :data="i.image.data"
                class="w-6 opacity-60 hover:opacity-80 md:w-4" />
            </AppLink>
          </li>
        </ul>
      </div>
      <div class="mt-10 flex flex-col items-center justify-between gap-2 py-1.5 md:flex-row">
        <a href="https://heydata.eu" target="_blank">
          <img
            alt="heydata trusted logo"
            src="https://privacy-seal.heydata.eu/privacy-seal/7ca5b381-c2f3-447d-b0b7-57db3f278997?lang=de&design=design2"
            style="border: 0"
            width="80"
            height="80"
            loading="lazy" />
        </a>
        <span class="order-last block text-center text-sm text-gray-500 md:order-first"
          >&copy; {{ currentYear }} haelsi. All rights reserved.</span
        >

        <ul class="flex justify-center gap-14 text-gray-800">
          <li v-for="link in data?.bottomLinkSection?.links" :key="link.id" class="mb-4 text-sm">
            <NuxtLink :to="link.href" :target="isOutgoing(link.href) ? '_blank' : '_self'" class="hover:underline"
              >{{ link.label }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="wave__footer absolute bottom-0 left-0 z-negative h-1/4 w-full overflow-hidden bg-bottom bg-no-repeat md:h-1/3 lg:h-96 xl:h-2/4 2xl:bg-full" />
  </footer>
</template>

<script setup lang="ts">
import { stringify } from "qs";
import type { Department } from "~/types/strapi/api/department/content-types/department/department";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";
import type { SeaContent } from "~/types/strapi/components/department/interfaces/SeaContent";
import type { Footer } from "~/types/strapi/components/global/interfaces/Footer.ts";

type CustomSeaContent = Pick<SeaContent, "slug" | "title"> & { id: number };

type DepartmentAttributes = Department["attributes"];
type PickedDepartmentAttributes = Pick<DepartmentAttributes, "title"> & {
  sea?: CustomSeaContent[];
};
type DepartmentId = Department["id"];
interface CustomDepartment {
  id: DepartmentId;
  attributes: PickedDepartmentAttributes;
}

defineProps<{
  data?: Footer;
}>();

const currentYear = new Date().getFullYear();

function isOutgoing(href: any) {
  return typeof href === "string" && href.startsWith("http");
}

const departmentQuery = stringify(
  {
    fields: ["title"],
    filters: {
      sea: {
        slug: {
          $notNull: true,
        },
      },
    },
    locale: ["de"],
    pagination: {
      page: 1,
      pageSize: 100,
    },
    populate: {
      sea: {
        fields: ["slug", "title"],
      },
    },
    publicationState: "live",
  },
  { encodeValuesOnly: true },
);

const { data: departmentResponse } = await useApiFetch<Payload<CustomDepartment[]>>(
  `/api/departments?${departmentQuery}`,
);

const departments = computed(() => departmentResponse.value?.data ?? []);
</script>

<style scoped>
.wave__footer {
  background-image: url("assets/svg/waveIllustration.svg");
}
</style>
